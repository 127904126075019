import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Card, Row, Col, Label,
} from 'reactstrap';
import CARD_INFO from '../../const/cardInformation';

class CardInformation extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { listResume } = this.props;

    const model = (listResume && listResume.data) || {};

    return (
      <Row className="mt-4">
        <Col className="mt-2">
          <Card className="pt-4 pl-4 pr-4 pb-4 rounded-0">
            <Row className="justify-content-center">
              <Col md="10" className="float-left">
                <Label className="font-weight-bold mb-3">
                  {`${CARD_INFO.HELLO}${model.FullName}`}
                </Label>
                <Label className="mb-3">
                  {CARD_INFO.SENDED_INFO}
                </Label>
                <Label>
                  {CARD_INFO.PAYMENT_IN}
                  {model.HasCreditCard && (
                    <>
                      <strong className="font-sm">
                        {` ${model.CreditCard.value.BRLCurrency()} (${model.InstallmentCount}x de ${model.InstallmentValue && model.InstallmentValue.BRLCurrency()}) `}
                      </strong>
                      {CARD_INFO.USING_CREDIT}
                      {`${model.HasBalance || model.HasOpenFinance || model.HasPoints ? ' e ' : '.'}`}
                    </>
                  )}
                  {model.HasBalance && (
                    <>
                      <strong className="font-sm">
                        {`${model.Balance && model.Balance.value.BRLCurrency()}`}
                      </strong>
                      {CARD_INFO.USING_BALANCE}
                    </>
                  )}
                  {model.HasOpenFinance && (
                    <>
                      <strong className="font-sm">
                        {`${model.OpenFinance && model.OpenFinance.value.BRLCurrency()}`}
                        {CARD_INFO.USING_OPENFINANCE}
                      </strong>
                    </>
                  )}
                  {model.HasPoints && (
                    <>
                      <strong className="font-sm">
                        {`${model.Points && model.Points.value.BRLCurrency()}`}
                      </strong>
                      {CARD_INFO.USING_POINTS}
                    </>
                  )}
                </Label>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}

CardInformation.defaultProps = {
};

CardInformation.propTypes = {
  listResume: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch,
);

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CardInformation);
