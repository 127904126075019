import Model from '../model/card';
import { encryptStringWithRSAPublicKey } from '../lib/encrypt';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Id = view.id;
    model.Brand = view.brand;
    model.LastFourDigits = view.lastFourDigits;
    model.ThumbUrl = view.thumbUrl;
    model.Principal = view.principal;

    return model;
  } catch (error) {
    throw new Error(`CardMapper - ViewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach((model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`CardMapper - ViewsModelToModels = ${error}`);
  }
};

export const modelToViewModel = (model, props) => {
  try {
    if (!model) return null;

    const view = {};
    const resume = props.data;

    view.nameOnCard = model.CreditCardName;
    view.document = model.Document ? model.Document.replace(/\D/g, '') : '';
    view.cardNumber = model.CreditCardNumber ? model.CreditCardNumber.replace(/\D/g, '') : '';
    view.expirationDate = model.DateValidate;
    view.cardKey = model.Cvv;
    view.saveCard = model.SaveCard;

    const encrypt = encryptStringWithRSAPublicKey(view, resume.PublicKey);
    const data = { data: encrypt };

    return data;
  } catch (error) {
    throw new Error(`CardMapper - ModelToViewModel = ${error}`);
  }
};
