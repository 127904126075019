import { axiosInstance as axios } from '../lib/axios';
import Config from '../config/environments';
import { viewModelToModel } from '../mapper/resume';
import { LIST_RESUME, POST_VALIDATE } from '../actionTypes/resume';
import updateState from './common/common';

export const getCheckout = (checkoutToken) => async (dispatch) => {
  const base = {
    type: LIST_RESUME,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.RESUME.URL}/${checkoutToken}`;

  axios
    .get(url)
    .then(async (response) => {
      base.payload = updateState(response);
      base.payload.data = viewModelToModel(base.payload.data);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};

export const stateDataValidate = (checkoutToken) => async (dispatch) => {
  const base = {
    type: POST_VALIDATE,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.RESUME.URL}/${checkoutToken}${Config.RESUME.VALIDATE}`;

  axios
    .post(url)
    .then((response) => {
      base.payload = updateState(response);
      base.payload.data = viewModelToModel(base.payload.data);
    })
    .catch((error) => {
      base.payload = error;
    })
    .finally(() => {
      dispatch(base);
    });

  dispatch(base);
};
