import Model from '../model/confirm';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Id = view.id;
    model.Status = view.status;

    return model;
  } catch (error) {
    throw new Error(`ConfirmMapper - ViewModelToModel = ${error}`);
  }
};

export const modelToViewModel = (model, props) => {
  try {
    if (!model) return null;

    const view = {};

    view.validationToken = model.SmsToken;
    view.paymentOptionId = props.data.Points.optionId;

    return view;
  } catch (error) {
    throw new Error(`ConfirmMapper - ModelToViewModel = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`ConfirmMapper - ModelsToViewsModel = ${error}`);
  }
};

export const viewModelToState = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.AuthOptionId = view.AuthOptionId;

    return model;
  } catch (error) {
    throw new Error(`ConfirmMapper - ViewModelToState = ${error}`);
  }
};
