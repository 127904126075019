import axios from 'axios';
import btoa from 'btoa';
import Config from '../config/environments';
import validation from './validation';
import { checkIdle, updateLastAPICallTime } from '../service/user';

const baseURL = Config.END_POINT;

const baseRequest = {
  baseURL,
  withCredentials: true,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa('pay2bapi@pay2b.io:123456')}`,
  },
};

const axiosInstance = axios.create(baseRequest);
const axiosLogin = axios.create(baseRequest);

const checkingInterceptor = (config) => {
  checkIdle();
  updateLastAPICallTime();
  validation(config.response);
  return config;
};

axiosInstance.interceptors.response.use(validation, checkingInterceptor);
axiosLogin.interceptors.response.use(validation, validation);

export { axiosInstance, axiosLogin };
