import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Card, Row, Col, Label, Media, Input, Form,
} from 'reactstrap';
import CHECKOUT from '../../const/checkout';

class PaymentTypeForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      SelectedMethod: [],
      Payments: [
        {
          Checked: false,
          Img: 'assets/icons/card-icon.svg',
          Name: 'Novo cartão de crédito',
          Description: 'pague em até 12x',
        }, {
          Checked: false,
          Img: 'assets/icons/pix-icon.svg',
          Name: 'Pix',
          Description: '',
          ImgDescription: 'assets/imgs/open-finance.svg',
        }, {
          Title: 'Utilizar saldo em carteira?',
          Checked: false,
          Img: 'assets/icons/wallet-icon.svg',
          Name: 'Carteira',
          Description: 'pague valor total ou parcial',
        }, {
          Title: 'Possui pontos fidelidade?',
          Checked: false,
          Img: 'assets/imgs/livelo.png',
          Name: 'Pontos',
          Description: 'pague valor total ou parcial',
        },
      ],
    };
  }

  handleCheck(idx) {
    const { SelectedMethod } = this.state;

    let checkeds = [...SelectedMethod];

    if (SelectedMethod.includes(idx)) {
      const currentIdx = SelectedMethod.indexOf(idx);
      checkeds.splice(currentIdx, 1);
    } else {
      checkeds = [...checkeds, idx];
    }

    this.setState({ SelectedMethod: checkeds });
  }

  render() {
    const { Payments, SelectedMethod } = this.state;

    return (
      <>
        <Row>
          <Col>
            <h2 className="font-lg font-weight-bold">{CHECKOUT.PAYMENT_TYPE_FORM.HOW_PAY}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <Card className="py-3 px-4 rounded-0 mb-0">
                {Payments.map((payment, idx) => (
                  <div key={payment.Name}>
                    <Row className="justify-content-center">
                      <Col md="6">
                        <h5 className="font-xm mt-1 mb-0 font-weight-bold">
                          {payment.Title}
                        </h5>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col md="6">
                        <Card className={`mb-1 px-4 py-${payment.Description ? 1 : 2} rounded card-payment`}>
                          <Row>
                            <Col xs="2" className="align-self-center">
                              <Input
                                className="m-0 position-relative mt-1"
                                id={`CheckboxPaymentType-${idx + 1}`}
                                name={`CheckboxPaymentType-${idx + 1}`}
                                type={idx > 1 ? 'checkbox' : 'radio'}
                                checked={SelectedMethod.includes(idx)}
                                value={SelectedMethod.includes(idx)}
                                onChange={() => this.handleCheck(idx)}
                                onClick={() => this.handleCheck(idx)}
                                style={{ width: 20, height: 20 }}
                              />
                            </Col>
                            <Col xs="10">
                              <Row>
                                <Col xs="2" className="p-0 d-flex justify-content-center align-self-center">
                                  <Media style={{ maxWidth: 35 }} src={payment.Img} alt={`Ícone ${payment.Name}`} />
                                </Col>
                                <Col xs="10" className="align-self-center">
                                  <Label className="font-weight-bold m-0">{payment.Name}</Label>
                                  {payment.Description ? (
                                    <Label className="m-0 text-gray font-xm">{payment.Description}</Label>
                                  ) : (
                                    <Media src={payment.ImgDescription} alt="Logo OpenFinance" />
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                ))}
              </Card>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

PaymentTypeForm.defaultProps = {
};

PaymentTypeForm.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape(),
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape() }).isRequired,
  listResume: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  listResume: state.resumeState.listResume,
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTypeForm);
