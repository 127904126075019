import { LIST_BANKS } from '../actionTypes/banks';

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  listBanks: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_BANKS:
      return { ...state, listBanks: action.payload };
    default:
      return state;
  }
};
