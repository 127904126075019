/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable operator-linebreak */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Fingerprint from "fingerprintjs2";
import publicIp from "public-ip";
import { geolocated } from "react-geolocated";
import PropTypes from "prop-types";
import { Button, Col, Form, Label, Row } from "reactstrap";
import { dataCollector, client } from "braintree-web";
import ReactLoading from "react-loading";
import Braintree from "../../lib/braintree";
import eventEmitter from "../../lib/eventEmitter";
import { getDataBanks } from "../../actions/banks";
import { modelToViewModel as cardModelToViewModel } from "../../mapper/card";
import { modelToViewModel } from "../../mapper/checkout";
import { postDataCheckout } from "../../actions/checkout";
import {
  LoyaltyFormComponent,
  CreditCardFormComponent,
  OpenFinanceFormComponent,
  BalanceFormComponent,
  PaymentTypeFormComponent,
  CardInfoComponent,
} from ".";
import Model from "../../model/payment";
import CHECKOUT from "../../const/checkout";

class PaymentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...Model,
      disableButton: false,
    };

    this.handleSubmitConfirmPayment =
      this.handleSubmitConfirmPayment.bind(this);
    this.handleChangeState = this.handleChangeState.bind(this);
    this.handleChangeMethod = this.handleChangeMethod.bind(this);

    Fingerprint.getPromise().then((result) => {
      this.setState({ Finger: result });
    });

    publicIp.v4().then((result) => {
      this.setState({ IpAddress: result });
    });
  }

  async componentDidMount() {
    const token = await Braintree().clientToken.generate();

    const clientCreated = await client.create({
      authorization: token.clientToken,
    });

    const data = await dataCollector.create({ client: clientCreated });

    this.setState({ DeviceData: data.deviceData });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { listResume, stateConfirmToken, statePostCheckout } = nextProps;
    const { Initialized, Requested, Paid } = nextState;
    const model = (listResume && listResume.data) || {};
    const voucherConfirmed = model.VoucherStatus === "CONFIRMED";
    const actualPayment =
      model.PaymentInstruments[
        voucherConfirmed && model.PaymentInstruments.length > 1 ? 1 : 0
      ].type;
    const lastMethod =
      model.PaymentInstruments[model.PaymentInstruments.length - 1].type;
    const isLastMethod =
      (actualPayment === "BALANCE" ||
        (actualPayment === "LOYALTY_POINTS" && voucherConfirmed)) &&
      lastMethod === "CREDIT_CARD";
    const response = (statePostCheckout && statePostCheckout.data) || {};

    if (listResume && listResume.state === false && Initialized) {
      if (listResume && listResume.data) {
        this.setState({
          Initialized: false,
          ActualPayment: actualPayment,
          IsLastMethod:
            (model.PaymentInstruments &&
              model.PaymentInstruments[model.PaymentInstruments.length - 1]
                .type === actualPayment) ||
            isLastMethod,
        });

        if (lastMethod === "OPEN_FINANCE") {
          nextProps.getDataBanks();
          this.setState({ ConfirmOpenFinance: false });
        }
      }
    }

    if (stateConfirmToken && stateConfirmToken.state === false && Requested) {
      if (stateConfirmToken && stateConfirmToken.success) {
        this.setState({ Requested: false });
        this.handleChangeMethod();
      }
    }

    if (statePostCheckout && statePostCheckout.state === false && Paid) {
      if (statePostCheckout && statePostCheckout.data && response.ConsentUrl) {
        this.setState({ Paid: false });
        window.open(response.ConsentUrl, "_self");
      }
    }

    return true;
  }

  async handleSubmitConfirmPayment(event) {
    event.preventDefault();

    eventEmitter.clear("error");

    const p = this.props;
    const { DeviceData, IsLastMethod, ConfirmOpenFinance } = this.state;

    if (IsLastMethod && ConfirmOpenFinance) {
      const state = { ...this.state };

      this.setState({
        Requested: true,
        disableButton: true,
      });

      const creditCardRequest = cardModelToViewModel(this.state, p.listResume);

      const confirmPaymentRequest = modelToViewModel(
        state,
        p.listResume,
        p.coords,
        creditCardRequest,
        p.match
      );

      p.postDataCheckout(
        confirmPaymentRequest,
        p.match.params.token,
        DeviceData
      );

      this.setState({
        Requested: false,
        Paid: true,
      });
    } else {
      this.handleChangeMethod(event);
    }
  }

  handleChangeState(event, key, value) {
    if (event) {
      event.preventDefault();
    }

    this.setState({ [key]: value });
  }

  handleChangeMethod(event) {
    event.preventDefault();

    const { ActualPayment, IsLastMethod, ConfirmOpenFinance, SelectedBankId } =
      this.state;
    const { listResume } = this.props;

    if (SelectedBankId) {
      if (IsLastMethod && !ConfirmOpenFinance) {
        this.setState({ ConfirmOpenFinance: true });
      } else {
        const model = (listResume && listResume.data) || {};
        const voucherConfirmed = model.VoucherStatus === "CONFIRMED";
        const nextMethodIdx = model.PaymentInstruments.findIndex(
          (payment) => payment.type === ActualPayment
        );
        const lastMethod =
          model.PaymentInstruments[model.PaymentInstruments.length - 1].type;
        const nexMethod =
          model.PaymentInstruments[
            nextMethodIdx +
              ((ActualPayment === "BALANCE" ||
                (ActualPayment === "LOYALTY_POINTS" && voucherConfirmed)) &&
              lastMethod !== "CREDIT_CARD" &&
              model.HasCreditCard
                ? 2
                : 1)
          ].type;
        const isLastMethod =
          (ActualPayment === "BALANCE" ||
            (ActualPayment === "LOYALTY_POINTS" && voucherConfirmed)) &&
          lastMethod === "CREDIT_CARD";

        this.setState({
          ActualPayment: nexMethod,
          IsLastMethod:
            (model.PaymentInstruments &&
              model.PaymentInstruments[model.PaymentInstruments.length - 1]
                .type === nexMethod) ||
            isLastMethod,
        });
      }
    }
  }

  render() {
    const {
      Requested,
      ActualPayment,
      IsLastMethod,
      ConfirmOpenFinance,
      disableButton,
    } = this.state;
    const { isMobile, match, statePostCheckout, location, listResume } =
      this.props;
    const model = (listResume && listResume.data) || {};

    const getLabelButton = (actualPayment) => {
      if (actualPayment === "OPEN_FINANCE") {
        return CHECKOUT.FORM.AUTHORIZE_PAYMENT;
      }
      return CHECKOUT.FORM.CONFIRM_PAYMENT;
    };

    const errors = eventEmitter.getByName("error");

    return (
      <>
        <Row>
          {!(
            IsLastMethod &&
            ActualPayment === "OPEN_FINANCE" &&
            ConfirmOpenFinance
          ) && (
            <Col>
              <CardInfoComponent listResume={listResume} />
            </Col>
          )}
        </Row>
        <Row className="justify-content-center">
          <Col md="12">
            <Form
              onSubmit={
                ActualPayment === "LOYALTY_POINTS" &&
                model.VoucherStatus !== "CONFIRMED"
                  ? () => {}
                  : this.handleSubmitConfirmPayment
              }
            >
              {!model.HasPreDefinedPayment && <PaymentTypeFormComponent />}
              {(ActualPayment === "LOYALTY_POINTS" ||
                model.VoucherStatus === "CONFIRMED") &&
                ActualPayment !== "OPEN_FINANCE" && (
                  <LoyaltyFormComponent
                    isMobile={isMobile}
                    match={match}
                    handleChangeState={this.handleChangeState}
                  />
                )}
              {ActualPayment === "BALANCE" && (
                <BalanceFormComponent isMobile={isMobile} />
              )}
              {((ActualPayment === "BALANCE" && model.HasCreditCard) ||
                (ActualPayment === "LOYALTY_POINTS" &&
                  model.VoucherStatus === "CONFIRMED" &&
                  model.HasCreditCard) ||
                ActualPayment === "CREDIT_CARD") && (
                <CreditCardFormComponent
                  isMobile={isMobile}
                  location={location}
                  handleChangeState={this.handleChangeState}
                />
              )}
              {ActualPayment === "OPEN_FINANCE" && (
                <OpenFinanceFormComponent
                  handleChangeState={this.handleChangeState}
                  isMobile={isMobile}
                  confirmSection={ConfirmOpenFinance}
                />
              )}
              {(ActualPayment !== "LOYALTY_POINTS" ||
                (model.PaymentInstruments &&
                  model.PaymentInstruments.length === 1 &&
                  model.VoucherStatus === "CONFIRMED")) && (
                <Col xs="12">
                  <Row className="justify-content-center text-center mb-2">
                    <Col md={6}>
                      {(statePostCheckout &&
                        statePostCheckout.state !== false) ||
                      Requested ? (
                        <Button
                          type="button"
                          color="primary"
                          className="rounded loading-balls font-lg w-100 m-0"
                        >
                          <ReactLoading
                            color="white"
                            height="100%"
                            width="100%"
                            type="bubbles"
                          />
                        </Button>
                      ) : (
                        <Button
                          type={disableButton ? "button" : "submit"}
                          color="primary"
                          className="rounded font-lg w-100 m-0 font-weight-bold"
                        >
                          {IsLastMethod && ConfirmOpenFinance
                            ? getLabelButton(ActualPayment)
                            : CHECKOUT.FORM.CONTINUE}
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row className="justify-content-center text-center mb-3">
                    {errors &&
                      errors.map((error) => (
                        <Label
                          style={{ color: "red", fontWeight: "700" }}
                          key={error}
                        >
                          {error}
                        </Label>
                      ))}
                  </Row>
                </Col>
              )}
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

PaymentForm.defaultProps = {
  coords: {},
};

PaymentForm.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape(),
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape() }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  listResume: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  stateConfirmToken: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  coords: PropTypes.shape({
    coords: PropTypes.shape(),
    isGeolocationAvailable: PropTypes.bool,
    isGeolocationEnabled: PropTypes.bool,
  }),
  postDataCheckout: PropTypes.func.isRequired,
  statePostCheckout: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getDataBanks: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      postDataCheckout,
      getDataBanks,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  listResume: state.resumeState.listResume,
  statePostCheckout: state.checkoutState.statePostCheckout,
  stateConfirmToken: state.tokenState.stateConfirmToken,
});

const PaymentFormGeoloc = geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(PaymentForm);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFormGeoloc);
