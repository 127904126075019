export default {
  Finger: null,
  IpAddress: null,
  authorization: null,
  client: null,
  DeviceData: null,
  CardRequest: '',
  TimeOut: 60000,
  Requested: false,
  ActualPayment: '',
  Initialized: true,
  CreditCardNumber: '',
  CreditCardName: '',
  DateOfBirth: '',
  Document: '',
  DateValidate: '',
  Cvv: '',
  SelectedBankId: '',
  IsLastMethod: false,
  ConfirmOpenFinance: true,
  Paid: false,
};
