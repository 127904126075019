import { REQUEST_TOKEN, CONFIRM_TOKEN } from '../actionTypes/token';

const BASE = {
  state: false,
  data: null,
};

const INITIAL_STATE = {
  stateRequestToken: BASE,
  stateConfirmToken: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_TOKEN:
      return { ...state, stateRequestToken: action.payload };
    case CONFIRM_TOKEN:
      return { ...state, stateConfirmToken: action.payload };
    default:
      return state;
  }
};
