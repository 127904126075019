export default {
  SelectPhone: '',
  SelectPhoneValid: null,
  SmsToken: '',
  SmsTokenValid: null,
  Uuid: '',
  CreditCardName: '',
  CreditCardNameValid: null,
  DateOfBirth: '',
  DateOfBirthValid: null,
  Document: '',
  DocumentValid: null,
  CreditCardNumber: '',
  CreditCardNumberValid: null,
  Cvv: '',
  CvvValid: null,
  DateValidate: '',
  DateValidateValid: null,
  CreditCardId: '',
  SaveCard: false,
  Focus: '',
  AuthCode: 0,
  AuthorizedAt: '',
  DeviceData: '',
  ConsentUrl: '',
  IsWaitingPayment: false,
  ErrorPayment: null,
};
