import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Card, Row, Col, Label, CardGroup, Media, Form, FormGroup, Input, Button,
} from 'reactstrap';
import Countdown from 'react-countdown';
import ReactLoading from 'react-loading';
import CHECKOUT from '../../const/checkout';
import eventEmitter from '../../lib/eventEmitter';
import TokenModel from '../../model/token';
import { postRequestToken, postConfirmToken } from '../../actions/token';
import { getCheckout } from '../../actions/resume';
import { modelToViewModel as tokenModelToViewModel } from '../../mapper/token';
import { modelToViewModel as confirmModelToViewModel } from '../../mapper/confirm';
import { modelToFilterDetail } from '../../mapper/resume';

class LoyaltyForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      SelectPhoneValid: null,
      SmsToken: '',
      SmsTokenValid: null,
      TimeOut: 60000,
      Requested: false,
      VerifySection: true,
      SelectPhone: '',
      ConfirmSection: false,
      TokenRequest: TokenModel,
    };
    this.handleSubmitRequestToken = this.handleSubmitRequestToken.bind(this);
    this.handleSubmitConfirmToken = this.handleSubmitConfirmToken.bind(this);
  }

  async handleSubmitRequestToken(event) {
    event.preventDefault();

    eventEmitter.clear('error');

    const { TimeOut } = this.state;
    const p = this.props;
    this.setState({ Requested: true });

    const stateRequestToken = tokenModelToViewModel(this.state);
    const requestToken = await p.postRequestToken(
      p.listResume.data.Points.id,
      stateRequestToken,
    );

    this.setState({
      VerifySection: !requestToken.success,
      ConfirmSection: requestToken.success,
      Requested: false,
      TokenRequest: requestToken.data,
      TimeOut: Date.now() + TimeOut,
    });
  }

  async handleSubmitConfirmToken(event) {
    event.preventDefault();

    eventEmitter.clear('error');

    const { TokenRequest } = this.state;
    const p = this.props;
    this.setState({ Requested: true });

    const stateConfirmToken = confirmModelToViewModel(this.state, p.listResume);
    const confirmToken = await p.postConfirmToken(
      p.listResume.data.Points.id,
      TokenRequest.Id,
      stateConfirmToken,
    );

    this.setState({
      VerifySection: false,
      ConfirmSection: !confirmToken.success,
      Requested: false,
    });

    const checkoutToken = modelToFilterDetail(p.match.params);
    p.getCheckout(checkoutToken);
    p.handleChangeState(event, 'Requested', true);
  }

  render() {
    const { listResume, isMobile, statePostCheckout } = this.props;
    const {
      SelectPhoneValid, SmsToken, SmsTokenValid, TimeOut, Requested, VerifySection,
      SelectPhone, ConfirmSection,
    } = this.state;

    const model = (listResume && listResume.data) || {};
    const errors = eventEmitter.getByName('error');

    return (
      <>
        {model.VoucherStatus === 'CONFIRMED' && (
          <CardGroup>
            <Card className="p-4 mb-3 rounded-0">
              <Row className="justify-content-center">
                <Col xs={isMobile ? 10 : 9}>
                  <Row className="m-0">
                    <Label className="font-weight-bold">
                      {CHECKOUT.LOAYALTY_FORM.TITLE}
                    </Label>
                  </Row>
                  <Label>
                    {CHECKOUT.LOAYALTY_FORM.READY}
                    <b style={{ color: '#8BC63E' }}>{model.Points.points}</b>
                    {CHECKOUT.LOAYALTY_FORM.TO_PAY}
                  </Label>
                </Col>
                <Col xs={isMobile ? 2 : 1} className="p-0 pl-3 align-self-center">
                  <Media
                    className="w-100"
                    object
                    src="assets/imgs/livelo.png"
                    alt="sompo"
                  />
                </Col>
              </Row>
            </Card>
          </CardGroup>
        )}
        <Row>
          <Col>
            <Card className={`p-4 rounded-0 m-0 ${model.VoucherStatus === 'CONFIRMED' ? 'd-none' : ''} mb-4`}>
              <Row className="justify-content-center">
                <Col xs={isMobile ? 10 : 9}>
                  {(VerifySection || ConfirmSection) && (
                    <>
                      <Row>
                        <Col>
                          <Label className="font-weight-bold">
                            {CHECKOUT.LOAYALTY_FORM.TITLE}
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Label>
                            {CHECKOUT.LOAYALTY_FORM.ACCESS_POINTS}
                            <b>{CHECKOUT.LOAYALTY_FORM.LIVELO}</b>
                            {CHECKOUT.LOAYALTY_FORM.WE_NEED}
                          </Label>
                        </Col>
                      </Row>
                    </>
                  )}
                  {VerifySection && (
                    <Row>
                      <Col>
                        <Label>
                          {CHECKOUT.LOAYALTY_FORM.CHOOSE_NUMBER}
                        </Label>
                      </Col>
                    </Row>
                  )}
                  {ConfirmSection && (
                    <Row>
                      <Col>
                        <Label>
                          {CHECKOUT.LOAYALTY_FORM.INSERT_CODE}
                          <b>{CHECKOUT.LOAYALTY_FORM.SMS}</b>
                          {CHECKOUT.LOAYALTY_FORM.TO}
                          <b>
                            {model && model.Phones.filter(
                              (phone) => phone.id === SelectPhone,
                            )[0].content}
                          </b>
                        </Label>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col xs={isMobile ? 2 : 1} className="p-0 pl-3">
                  <Media
                    className="w-100"
                    object
                    src="assets/imgs/livelo.png"
                    alt="sompo"
                  />
                </Col>
              </Row>
              {VerifySection && (
                <Form onSubmit={this.handleSubmitRequestToken}>
                  <FormGroup row className="my-0 justify-content-center">
                    <Col xs="12" md="7" style={{ margin: '10px 0px' }}>
                      <FormGroup className={isMobile ? 'mb-2' : 'mb-0'}>
                        <Input
                          type="select"
                          required="required"
                          id="SelectPhone"
                          name="select"
                          value={SelectPhone}
                          valid={SelectPhoneValid}
                          invalid={SelectPhoneValid === false}
                          disabled={Requested}
                          onChange={(e) => this.setState({
                            SelectPhone: e.target.value,
                            SelectPhoneValid: e.target.validity.valid,
                          })}
                        >
                          <option defaultValue disabled value="">Selecione um telefone</option>
                          {
                            model && model.Phones && model.Phones.map((phone) => (
                              <option
                                value={phone.id}
                                key={phone.id}
                              >
                                {phone.content}
                              </option>
                            ))
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="3" className="d-flex align-items-center">
                      <Row className="justify-content-center text-center w-100 m-0">
                        <Col className="w-100 p-0">
                          {
                            (statePostCheckout && statePostCheckout.state !== false)
                              || Requested
                              ? (
                                <Button type="button" color="primary" className="px-4 loading-balls rounded w-100 m-0">
                                  <ReactLoading color="white" height="100%" width="100%" type="bubbles" className="d-flex mt-1" />
                                </Button>
                              )
                              : (
                                <Button color="primary" className="font-lg rounded w-100 m-0">
                                  {CHECKOUT.FORM.SEND_BUTTON}
                                </Button>
                              )
                          }
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="10">
                      <Row className="justify-content-center text-center">
                        {
                          errors && errors.map((error) => (
                            <Label style={{ color: 'red' }} key={error}>{error}</Label>
                          ))
                        }
                      </Row>
                    </Col>
                  </FormGroup>
                </Form>
              )}
              {ConfirmSection && (
                <>
                  <Form onSubmit={this.handleSubmitConfirmToken}>
                    <FormGroup row className="my-0 justify-content-center">
                      <Col md="7" xs="12" style={{ margin: '10px 0px' }}>
                        <Input
                          type="text"
                          required="required"
                          maxLength="12"
                          minLength="6"
                          id="SmsToken"
                          name="SmsToken"
                          value={SmsToken}
                          valid={SmsTokenValid}
                          invalid={SmsTokenValid === false}
                          disabled={Requested}
                          placeholder={CHECKOUT.FORM.SMS_TOKEN_PLACEHOLDER}
                          onChange={(e) => this.setState({
                            SmsToken: e.target.value,
                            SmsTokenValid: e.target.validity.valid,
                          })}
                        />
                        <div style={{
                          position: 'absolute',
                          right: '50px',
                          top: '5px',
                          fontWeight: '700',
                          fontSize: '19px',
                        }}
                        >
                          <Countdown
                            date={TimeOut}
                            precision={2}
                            zeroPadTime={2}
                            onChange={(e) => this.setState({ TimeOut: e })}
                            onComplete={() => {
                              eventEmitter.guard('error', ('Limite de tempo excedido'));
                              this.setState({
                                ConfirmSection: false,
                                VerifySection: true,
                                TimeOut: 60000,
                              });
                            }}
                            renderer={(count) => (count.seconds > 30
                              ? (
                                <span>
                                  {`${count.formatted.minutes}:${count.formatted.seconds}`}
                                </span>
                              )
                              : (
                                <span style={{ color: 'red' }}>
                                  {`${count.formatted.minutes}:${count.formatted.seconds}`}
                                </span>
                              ))}
                          />
                        </div>
                      </Col>
                      <Col md="3" xs="12" className="d-flex">
                        <Row className="justify-content-center text-center w-100 m-0">
                          <Col className="d-flex w-100 align-items-center p-0">
                            {
                              (statePostCheckout && statePostCheckout.state !== false)
                                || Requested
                                ? (
                                  <Button type="button" color="primary" className="px-4 loading-balls rounded w-100 m-0">
                                    <ReactLoading color="white" height="100%" width="100%" type="bubbles" className="d-flex mt-1" />
                                  </Button>
                                )
                                : (
                                  <Button color="primary" className="font-lg rounded w-100 m-0">
                                    {CHECKOUT.FORM.CONFIRM_BUTTON}
                                  </Button>
                                )
                            }
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="10">
                        <Row className="justify-content-center text-center">
                          {
                            errors && errors.map((error) => (
                              <Label style={{ color: 'red' }} key={error}>{error}</Label>
                            ))
                          }
                        </Row>
                      </Col>
                    </FormGroup>
                  </Form>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

LoyaltyForm.defaultProps = {
};

LoyaltyForm.propTypes = {
  listResume: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape() }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  statePostCheckout: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  postRequestToken: PropTypes.func.isRequired,
  postConfirmToken: PropTypes.func.isRequired,
  getCheckout: PropTypes.func.isRequired,
  handleChangeState: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    postRequestToken,
    postConfirmToken,
    getCheckout,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  statePostCheckout: state.checkoutState.statePostCheckout,
  listResume: state.resumeState.listResume,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyForm);
