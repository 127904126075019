/* eslint-disable quotes */
export default {
  LOADING: "Aguardando...",
  NOTIFY_SUCCESS: "Usuário cadastrado com sucesso!",
  TITLE: "Checkout",
  TITLE_PAYMENT: "Pagamento",
  SUB_TITLE_PAYMENT: "Veja abaixo as formas de pagamento disponíveis.",
  TITLE_RESUME: "Resumo do Pagamento",
  SUB_TITLE_RESUME: "Confira as informações do seu pedido.",
  LOAYALTY_FORM: {
    TITLE: " Pagamento com pontos",
    READY: "Pronto, reservamos ",
    TO_PAY: " para pagar seu seguro.",
    ACCESS_POINTS: "Para termos acesso aos seus pontos ",
    LIVELO: "Livelo",
    WE_NEED: " precisamos que você realize a verificação de segurança abaixo.",
    CHOOSE_NUMBER:
      "Escolha um número de celular abaixo para receber o código de autenticação via SMS. Caso nenhum número seja válido, acesse a plataforma da Livelo para atualização cadastral.",
    INSERT_CODE: "Insira o código que enviamos via ",
    SMS: "SMS",
    TO: " para ",
  },
  PAYMENT_TYPE_FORM: {
    HOW_PAY: "Como você deseja pagar?",
  },
  OPEN_FINANCE_FORM: {
    CHOOSE_ONE_INSTITUTIONS: "Escolha uma instituição de pagamento",
    INSTITUTIONS: "Instituições mais frequentes",
    COMPLETE_LIST: "Lista completa",
    TITLE: "Confirme se está tudo certo",
    YOU_PAY: "Você vai pagar no PIX",
    ACCOUNT_ORIGIN: "Conta de origem:",
    CONFIRM_TRANSACTION:
      "É necessário confirmar a transação no aplicativo da instituição selecionada para prosseguir.",
    CLOSE_TAB:
      "O fechamento da aba ou aplicativo da instituição selecionada pode te impedir de concluir a transação.",
    DONT_WORRY:
      "Não se preocupe: esse redirecionamento é seguro e realizado através da Open Finance.",
  },
  BALANCE_FORM: {
    TITLE: "Pagamento com Saldo",
    ALREADY: "Pronto, reservamos ",
    YOUR_BALANCE: " da sua carteira para pagar o seu seguro.",
  },
  FORM: {
    CONTINUE: "Continuar",
    AUTHORIZE_PAYMENT: "Autorizar Pagamento",
    CONFIRM_PAYMENT: "Confirmar Pagamento",
    SEND_BUTTON: "Enviar",
    CONFIRM_BUTTON: "Enviar",
    TITLE_CARD_SECTION: "Cartão de Crédito",
    TITLE_VERIFY_SECTION: "Verificação por SMS",
    TITLE_CONFIRMATION_SECTION: "Confirmação do SMS",
    TITLE_CARDS: "Escolha o cartão que deseja usar pela bandeira e final.",
    PHONES_FIELD: "Celular:",
    NAME_FIELD: "Nome completo do titular:",
    NAME_PLACEHOLDER: "Nome completo",
    CREDIT_CARD_NUMBER_FIELD: "Número do cartão de crédito:",
    CREDIT_CARD_NUMBER_PLACEHOLDER: "Número do cartão",
    SMS_TOKEN: "Código recebido",
    SMS_TOKEN_PLACEHOLDER: "123456",
    DATE_BIRTH_FIELD: "Data de nascimento:",
    DATE_BIRTH_PLACEHOLDER: "Data de nascimento",
    DOCUMENT_FIELD: "Documento:",
    DOCUMENT_PLACEHOLDER: "CPF do titular do cartão",
    CVV_FIELD: "Cód. de segurança:",
    CVV_PLACEHOLDER: "Cód. de segurança",
    DATE_VALIDATE_FIELD: "Data de validade:",
    DATE_VALIDATE_PLACEHOLDER: "Data de vencimento",
    INSTALLMENTS_FIELD: "Parcelamento:",
    INSTALLMENTS_PLACEHOLDER: "Selecione uma parcela",
    SAVE_CARD_FIELD: "Salvar este cartão para compras futuras",
  },
  DETAILS: {
    PROPOSAL_DATA: "Dados da proposta",
    PROPOSAL_VALUE: "Valor da negociação",
    NAME: "Nome:",
    PROPOSAL_NUMBER: "Número da Proposta:",
    VEHICLE_PLATE: "Placa do Veículo",
    TOTAL_PRIZE: "Valor Total:",
    VOUCHERS: "Livelo:",
    BALANCE: "Saldo:",
    PIX: "Pix:",
    BALANCE_PAY: "Crédito:",
    VALUE: "Valor restante a ser pago:",
    BALANCE_PAID: "Valor final em BRL",
    SPLIT: "Parcelado em",
    CPF: "CPF:",
    CNPJ: "CNPJ:",
  },
};
