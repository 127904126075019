import Model from '../model/banks';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Id = view.id;
    model.Name = view.name;
    model.Slug = view.slug;
    model.Avatar = view.avatar;

    return model;
  } catch (error) {
    throw new Error(`CheckoutMapper - viewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.participants.forEach((model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    const viewsPage = {
      list: views,
    };

    return viewsPage;
  } catch (error) {
    throw new Error(`CheckoutMapper - viewsModelToModels = ${error}`);
  }
};
