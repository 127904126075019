import NodeRSA from 'node-rsa';

export const encryptStringWithRSAPublicKey = (toEncrypt, publicKey) => {
  const key = new NodeRSA(publicKey);
  const dataEncrypt = key.encrypt(toEncrypt, 'base64');
  return dataEncrypt;
};

export const decryptStringWithRsaPrivateKey = (toDecrypt, privateKey) => {
  const key = new NodeRSA(privateKey);
  const dataDecrypt = key.decrypt(toDecrypt, 'utf-8');
  return dataDecrypt;
};

let payload = {
  brand: 'Visa',
  cardKey: '123',
  cardNumber: '4444555566667777',
  document: '28217706859',
  expirationDate: '07/2019',
  nameOnCard: 'Joao das Neves',
  nickname: '',
  billingAddress: {
    city: 'Rio De Janeiro',
    complement: 'Posto',
    country: 'BR',
    neighborhood: 'Sao cristovao',
    number: '329',
    state: 'RJ',
    street: 'Rua Francisco Eugenio',
    zipCode: '20921290',
  },
};

const dataPublicKey = '-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjd7yaw3Nfl/J7jBJouvn1gLglZuunyrJWt7AYqTtmHDtXYKtNl2/QtGGxS6BSZJCKYXdK31W4J7NTT+s3TqcgTj+g3K58yXRn5CnZu6QA2Xu8NYQclCdzu/aQE1hssFsBd3YC0lsseSjz55fL5/UfAqBzIqIib/Gtv3nclgrYoW0dqsMeLzPapQmLea8Jqg7UUiQ9p1a3PHB1CUxbY2kiiQ4UE3sj1ffDd9hfS6+kO8fxZCzlqFGKySvtPdh9Xohmi0rc9rstBIU20tgqkkNMp/hiA37hgsjT5ZhAfZDJ6U0IF5jGJ/Zdhm9oY1ff80b9N/5GsFmK7gaKRl02gCHuQIDAQAB-----END PUBLIC KEY-----';
const dataPrivateKey = '-----BEGIN PRIVATE KEY-----MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCN3vJrDc1+X8nuMEmi6+fWAuCVm66fKsla3sBipO2YcO1dgq02Xb9C0YbFLoFJkkIphd0rfVbgns1NP6zdOpyBOP6DcrnzJdGfkKdm7pADZe7w1hByUJ3O79pATWGywWwF3dgLSWyx5KPPnl8vn9R8CoHMioiJv8a2/edyWCtihbR2qwx4vM9qlCYt5rwmqDtRSJD2nVrc8cHUJTFtjaSKJDhQTeyPV98N32F9Lr6Q7x/FkLOWoUYrJK+092H1eiGaLStz2uy0EhTbS2CqSQ0yn+GIDfuGCyNPlmEB9kMnpTQgXmMYn9l2Gb2hjV9/zRv03/kawWYruBopGXTaAIe5AgMBAAECggEAFfLG+E7zWrjBCcuIqN7sSAy76Alk05UP/7H0e45ef7YA0vWzjHSmcVTvD7RpJhOtjrT6CY9jkG6g/nF6ceNucQN7RKTMJdTVAGWlsGP5/byYYcQMWVMlhu1Oi6ItGjWYW7P9h4l9emwP97/dpAWLHwGVISrRgtYbjRXs56DCXYRsRiZ0ZCXXflJPXpeOaiTp99V6/HO+sSc9gsT9DJgu2J77JMBhmOtYltiavLa4+1Ecg5e4mAADn43BuxZ8chKilzTMkGgf4H++BzZ4DHFJPFnObU1q5c+piPxT5czmdaXokN1v8CRcmLiT3aA7uxWlUSC0GoR9/eeZtuSQRzL8gQKBgQDd/rBC43srLt7w65t2KqN0Amz885wMfzGM6uyzDVjHEo4VD0LRc1S+JBHf3DT3R7w/RHfB491/l3K2dgmOtPRpMlpbOfvB0+62h+DQ2JLvSXwrbn972XhxkaP+1E9k1j5Y3YK9ppALFJzUnhA8IS481jnO60uX41J9UMQf7ai/iQKBgQCjmkZRoogR9iBfbWVhdoTJ0GYzLvH5753ADuemm6mBKV6LlwDLpKLdy+crfw38QeZAGshLQ4CMCQsLh7D2KEf+s/CDFcYDJJ+HqN8nvrC/NfItprpZ68hQhE6FK5YlofZQ7WpIdsaXLy/8oIKVPBh8bUygOBSPEw5WHCukKSLKsQKBgQCjYlGDyTzbeXGnyx/uBk+qkCZ6vSi6odpsJnLzDP2r9mIP6d15nfJextlI8x8gZ/GKdu/VCcn5oBNTauh52YCQ8/hAcBp0e3CTcsnpq5vo9SKoLHkzQ8k7fL9ATC3qvyu/57fTsQUOwqryaSr1tk37hIejXNWUAkCx7oGKJjYuUQKBgEI5tBddpJkouagHX4+Za11KZ+R2L2uOLJxPLESMJIxtU6FL6f2FUoxX+ubnkqC3iF4QuV2GIKYPDxKGxgKPAb2/1y8pBDPHgnBm0paDIYb1bmg+Ypb90+OL+0D7CNeI/W16/ZgBxJf0eQdZbiK85eiQUgFVUjUscJQ46Q2VvUixAoGAb/YqS6CXt8EwEjCK5VI0h+E6Mx6PbIjTOphcgCp6C+tzXZtQtj0hrJwoT1t5Qy+Dc2LtP2Pn6HOIpT0NnPkKEA9Qi6/kI51o3SHnhgXnaxxv5FQxD2T0FFnHiEw+vmYtv88WzC1Rl2dpzXsaEds9hTTP7oHCVJvYZrzNl141SZY=-----END PRIVATE KEY-----';

if (typeof payload === 'object') {
  payload = JSON.stringify(payload);
}

const dataEncrypt = encryptStringWithRSAPublicKey(payload, dataPublicKey);

decryptStringWithRsaPrivateKey(dataEncrypt, dataPrivateKey);
