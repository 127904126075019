import { axiosInstance as axios } from '../lib/axios';
import Config from '../config/environments';
import { LIST_BANKS } from '../actionTypes/banks';
import { viewsModelToModels } from '../mapper/banks';
import updateState from './common/common';

export const getDataBanks = () => async (dispatch) => {
  const base = {
    type: LIST_BANKS,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.OPENFINANCE.URL}${Config.OPENFINANCE.PARTICIPANTS}`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewsModelToModels(base.payload.data);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const clearDataBanks = () => async (dispatch) => {
  const base = {
    type: LIST_BANKS,
    payload: {
      data: null,
      state: false,
    },
  };

  dispatch(base);
};
