import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Button, Col } from 'reactstrap';
import { localSetNoExpired, localGetNoExpired } from '../../lib/session';

class Cookies extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleAccept = this.handleAccept.bind(this);
    this.state = {
      Accept: false,
    };
  }

  handleAccept(event) {
    event.preventDefault();
    localSetNoExpired('COOKIES', { accept: true });
    this.setState({ Accept: true });
  }

  render() {
    const { Accept } = this.state;
    const cookies = localGetNoExpired('COOKIES');
    const accepted = cookies && cookies.accept;

    return (
      !accepted && !Accept
      && (
      <>
        <div className="cookie-term">
          <Col md="12">
            <span className="title">
              Nosso site usa cookies
            </span>
          </Col>
          <Col md="10" className="float-left">
            <span>
              Nós usamos cookies para otimizar a funcionalidade
              do site e oferecer a melhor experiência possível.
              Para saber mais sobre os cookies que usamos, visite
              nossa Política de Cookies. Ao clicar em &quot;Aceitar&quot;,
              você concorda com o uso de cookies.
            </span>
          </Col>
          <Col md="2" className="float-left text-center">
            <Button type="button" onClick={this.handleAccept}>Aceitar</Button>
          </Col>
        </div>
      </>
      )
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch,
);

const mapStateToProps = () => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cookies));
