import { POST_CARD, LIST_CARD } from '../actionTypes/card';

const BASE = {
  state: false,
  data: null,
};

const INITIAL_STATE = {
  statePostCard: BASE,
  listCard: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_CARD:
      return { ...state, statePostCard: action.payload };
    case LIST_CARD:
      return { ...state, listCard: action.payload };
    default:
      return state;
  }
};
