export default {
  LOADING: 'Aguardando...',
  PAYMENT_CONFIRM: {
    PAYMENT_CONFIRMED: 'Pagamento confirmado com sucesso',
    WILL_RECEIVE:
      'Seu pagamento está confirmado, você receberá o comprovante no email ',
    CONFIRMED: 'CONFIRMED',
  },
  WAITING_CONFIRM: {
    WAITING: 'Aguardando a confirmação do pagamento',
    WILL_PROCESSED:
      'Seu pagamento será processado em breve, você receberá o comprovante no email ',
  },
  PAYMENT_RESUME: 'Resumo do pagamento',
  PAYMENT_POINTS: 'Pagamento com pontos',
  PAYMENT_BALANCE: 'Pagamento com saldo',
  CREDIT: 'Crédito',
  TOTAL_PRIZE: 'Valor Total:',
  PAYMENT_ERROR: {
    ERROR: 'Não foi possível realizar o seu pagamento',
    PAYMENT_RETRY: 'Tentar novamente',
    SORRY: 'Desculpe, ocorreu um erro. Por favor, tente novamente.',
  },
};
