import { cnpj, cpf } from 'cpf-cnpj-validator';
import moment from 'moment';

export function cnpjValidatorFunct(e, nameInput) {
  const { valid } = e.target.validity;
  const { required } = e.target;
  const size = nameInput.value.length;

  if (valid && required && !cnpj.isValid(nameInput.value)) {
    nameInput.setCustomValidity('CNPJ inválido');
    return;
  }

  if (valid && !required && size > 0 && !cnpj.isValid(nameInput.value)) {
    nameInput.setCustomValidity('CNPJ inválido');
    return;
  }

  nameInput.setCustomValidity('');
}

export function cpfValidatorFunct(e, nameInput) {
  const { required } = e.target;
  const size = nameInput.value.length;
  let { valid } = e.target.validity;

  if (required && !cpf.isValid(nameInput.value)) {
    nameInput.setCustomValidity('CPF inválido');
    valid = false;
    return;
  }

  if (!required && size > 0 && !cpf.isValid(nameInput.value)) {
    nameInput.setCustomValidity('CPF inválido');
    valid = false;
    return;
  }

  if (!valid) valid = true;

  nameInput.setCustomValidity('');
}

export function dateOfBirthValidatorFunct(e, nameInput) {
  const { required } = e.target;
  let { valid } = e.target.validity;

  const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
  const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100));

  valid = moment(nameInput.value, 'DD/MM/YYYY').isValid();
  valid = valid ? !moment(nameInput.value, 'DD/MM/YYYY').isAfter(maxDate) : false;
  valid = valid ? !moment(nameInput.value, 'DD/MM/YYYY').isBefore(minDate) : false;

  if (required && !valid) {
    nameInput.setCustomValidity('Data de nascimento inválida');
    return;
  }

  valid = true;
  nameInput.setCustomValidity('');
}

export function cardDateValidatorFunct(e, nameInput) {
  const { required } = e.target;
  let { valid } = e.target.validity;

  const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 100));
  const minDate = moment(new Date()).format('YYYY-MM');

  valid = moment(nameInput.value, 'MM/YYYY').isValid();
  valid = valid ? !moment(nameInput.value, 'MM/YYYY').isAfter(maxDate) : false;
  valid = valid ? !moment(nameInput.value, 'MM/YYYY').isBefore(minDate) : false;

  if (required && !valid) {
    nameInput.setCustomValidity('Data de validade inválida');
    return;
  }

  valid = true;
  nameInput.setCustomValidity('');
}
