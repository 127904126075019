export default {
  CompanyName: '',
  CompanyLogo: '',
  CustomerId: '',
  FirstName: '',
  LastName: '',
  FullName: '',
  Email: '',
  Document: '',
  ProposalNumber: '',
  TotalPrize: 0,
  VoucherValueId: '',
  VoucherOptionId: '',
  VoucherValue: 0,
  BalancePay: 0,
  InstallmentCount: 0,
  InstallmentValue: 0,
  Cards: [],
  PublicKey: '',
  Status: '',
  VoucherStatus: '',
  Cars: [],
  Phones: [],
  OnlyCreditCard: false,
  IconType: '',
  PaymentInstruments: [],
  BalanceValue: 0,
  OpenFinanceValue: 0,
  OpenFinanceId: '',
  OpenFinanceDescription: '',
};
