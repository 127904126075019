import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Row, Media, Col } from 'reactstrap';

class DefaultFooter extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { isResume, isCheckout } = this.props;
    return (
      <>
        <div className={`footer${isResume || isCheckout ? `-${isResume || isCheckout}` : ''}`}>
          <footer>
            <Row>
              <Col
                md="12"
                className="mt-0 text-center d-flex align-items-center justify-content-center"
                style={{
                  height: '72px', color: '#58595B', background: '#FFFFFF 0% 0% no-repeat padding-box', opacity: '1', fontSize: '19px',
                }}
              >
                <Media
                  className="logo"
                  object
                  src="assets/imgs/powered-by-icon.svg"
                  alt="Logo"
                  style={{ maxWidth: '106px' }}
                />
                <a href="https://www.pay2b.com.br/politica-de-privacidade" target="_blank" rel="noreferrer">
                  <span style={{ color: '#23282c', fontSize: 12 }}> Políticas de Privacidade</span>
                </a>
              </Col>
            </Row>
          </footer>
        </div>
      </>
    );
  }
}

DefaultFooter.defaultProps = {
  isResume: '',
  isCheckout: '',
};

DefaultFooter.propTypes = {
  isResume: PropTypes.string,
  isCheckout: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch,
);

const mapStateToProps = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultFooter));
