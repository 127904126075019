import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button, Card, Col, Row, Label, Media,
} from 'reactstrap';
import { axiosInstance as axios } from '../../lib/axios';

import Loading from '../utils/loading';
import RESUME from '../../const/resume';
import Model from '../../model/checkout';
import { DefaultLayout } from '../../containers';
import eventEmitter from '../../lib/eventEmitter';
import { getCheckout } from '../../actions/resume';

class Resume extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ...Model,
      Width: window.innerWidth,
      Requested: true,
    };

    this.handleResize = this.handleResize.bind(this);

    const { match } = props;
    const { params } = match;
    const { token } = params;

    const checkoutValidate = async () => {
      axios
        .post(`checkout/${token}/validate`)
        .then((response) => {
          const orderStatusPayment = !response.data.statusDetail.status;
          this.setState({
            IsWaitingPayment:
              orderStatusPayment === RESUME.PAYMENT_CONFIRM.CONFIRMED,
          });
        })
        .catch((error) => {
          this.setState({ ErrorPayment: error });
        });
    };

    if (token) {
      props.getCheckout(token);
      setTimeout(() => checkoutValidate(), 5000);
    }
  }

  componentDidMount() {
    const { match, listResume } = this.props;
    const { path } = match;

    this.setState({ IsWaitingPayment: path !== '/resume' }, () => {
      if ((!listResume || !listResume.data) && !(path !== '/resume')) {
        window.open('#/404', '_self');
      }
    });

    window.addEventListener('resize', this.handleResize.bind(this));
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { match } = this.props;
    const { stateValidate, listResume } = nextProps;
    const { Requested } = nextState;
    const { path } = match;

    const errors = eventEmitter.getByName('error');

    if (path !== nextProps.match.path) {
      this.setState({ IsWaitingPayment: nextProps.match.path !== '/resume' });
    }

    if (listResume && listResume.state === false && Requested) {
      if (listResume && listResume.data) {
        if (listResume.data.Status === 'PRE_AUTHORIZED_BY_GATEWAY') {
          window.open('#/resume', '_self');
        }
      }
    }

    if (stateValidate && stateValidate.state === false && Requested) {
      if (stateValidate && !stateValidate.success && errors.length > 0) {
        window.open(`#/checkout/${nextProps.match.params.token}`, '_self');
      }

      if (stateValidate && stateValidate.data) {
        this.setState({ Requested: false });

        window.open('#/resume', '_self');
      }
    }

    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    this.setState({ Width: window.innerWidth });
  }

  render() {
    const { statePostCheckout, listResume } = this.props;
    const { Width, IsWaitingPayment, ErrorPayment } = this.state;

    if (listResume && listResume.state !== false) {
      return <Loading msg={RESUME.LOADING} />;
    }

    const model = (listResume && listResume.data) || {};
    const modelCheckout = statePostCheckout ? statePostCheckout.data : {};
    const isMobile = Width <= 999;

    const getSVGIcon = () => {
      if (ErrorPayment != null) return 'error';
      return IsWaitingPayment ? 'clock' : 'checked';
    };

    const getStatusMessage = () => {
      if (ErrorPayment != null) return RESUME.PAYMENT_ERROR.ERROR;
      return IsWaitingPayment
        ? RESUME.WAITING_CONFIRM.WAITING
        : RESUME.PAYMENT_CONFIRM.PAYMENT_CONFIRMED;
    };

    const getStatusDescription = () => {
      if (ErrorPayment != null) return RESUME.PAYMENT_ERROR.SORRY;
      return IsWaitingPayment
        ? RESUME.WAITING_CONFIRM.WILL_PROCESSED
        : RESUME.PAYMENT_CONFIRM.WILL_RECEIVE;
    };

    return (
      <DefaultLayout isMobile={isMobile}>
        <Row
          className="justify-content-center "
          style={{ marginTop: isMobile ? 30 : 68 }}
        >
          <Col md="12" style={{ margin: 0 }}>
            <Card className="py-3 px-4 rounded-0">
              <Row className="justify-content-center">
                <Col md="0">
                  <Label className="font-lg font-weight-bold">
                    <Media
                      object
                      className="mr-2"
                      src={`assets/icons/${getSVGIcon()}-icon.svg`}
                      alt=""
                      style={{
                        width: 22,
                        height: 22,
                        transform: IsWaitingPayment ? 'none' : 'rotate(-10deg)',
                      }}
                    />
                    {getStatusMessage()}
                  </Label>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="12" style={{ margin: 0 }}>
            <Card className="py-3 px-4 rounded-0">
              <Row className="justify-content-center">
                <Col md="9">
                  <Label className="font-lg font-weight-bold">
                    {RESUME.PAYMENT_RESUME}
                  </Label>
                  <Label className="m-0">{getStatusDescription()}</Label>
                  <strong className="font-sm">
                    {ErrorPayment != null ? '' : model.Email}
                  </strong>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md="12" style={{ fontSize: '20px' }}>
            <Card className="py-3 px-4 rounded-0">
              <Row className="justify-content-center">
                <Col md="7" className="text-center mb-3">
                  <Label className="font-sm m-0">
                    {`Informações de pagamento atualizadas em ${
                      modelCheckout && modelCheckout.AuthorizedAt
                        ? modelCheckout.AuthorizedAt.toDateTimeLocale()
                        : new Date().toString().toDateTimeLocale()
                    }`}
                  </Label>
                </Col>
              </Row>
              {model.HasBalance && (
                <Row className="justify-content-center mb-1">
                  <Col
                    md="9"
                    className="d-flex align-items-center resume-mobile"
                  >
                    <Col xs="1" className="text-left p-0">
                      <Media
                        className="logo"
                        object
                        src="assets/icons/wallet-icon.svg"
                        alt="sompo"
                        style={{ height: '34px' }}
                      />
                    </Col>
                    <Col xs="6" className={isMobile ? '' : 'ml-3'}>
                      <Label className="font-lg m-0">
                        {RESUME.PAYMENT_BALANCE}
                      </Label>
                    </Col>
                    <Col xs="5" className="text-right">
                      <Label className="font-lg m-0">
                        {model.Balance.value.BRLCurrency()}
                      </Label>
                    </Col>
                  </Col>
                </Row>
              )}
              {model.HasOpenFinance && (
                <Row className="justify-content-center mb-1">
                  <Col
                    md="9"
                    className="d-flex align-items-center resume-mobile"
                  >
                    <Col xs="1" className="text-left p-0">
                      <Media
                        className="logo"
                        object
                        src="assets/icons/pix-icon.svg"
                        alt="sompo"
                        style={{ height: '34px' }}
                      />
                    </Col>
                    <Col xs="6" className="text-left">
                      <Media
                        className="logo"
                        object
                        src="assets/imgs/open-finance.svg"
                        alt="sompo"
                        style={{ height: '20px' }}
                      />
                    </Col>
                    <Col xs="5" className="text-right">
                      <Label className="font-lg m-0">
                        {model.OpenFinance.value.BRLCurrency()}
                      </Label>
                    </Col>
                  </Col>
                </Row>
              )}
              {model.HasPoints && (
                <Row className="justify-content-center mb-1">
                  <Col
                    md="9"
                    className="d-flex align-items-center resume-mobile"
                  >
                    <Col xs="1" className="text-left p-0">
                      <Media
                        className="logo"
                        object
                        src="assets/imgs/livelo.png"
                        alt="sompo"
                        style={{ height: '37px' }}
                      />
                    </Col>
                    <Col xs="6" className={isMobile ? '' : 'ml-3'}>
                      <Label className="font-lg m-0">
                        {RESUME.PAYMENT_POINTS}
                      </Label>
                    </Col>
                    <Col xs="5" className="text-right">
                      <Label className="font-lg m-0">
                        {`${model.Points.points} pontos Livelo`}
                      </Label>
                    </Col>
                  </Col>
                </Row>
              )}
              {model.HasCreditCard && (
                <Row className="justify-content-center mb-1">
                  <Col
                    md="9"
                    className="d-flex align-items-center resume-mobile"
                  >
                    <Col xs="1" className="text-left p-0">
                      <Media
                        className="logo"
                        object
                        src="assets/icons/credit-card-icon.svg"
                        alt="sompo"
                        style={{ height: '30px' }}
                      />
                    </Col>
                    <Col xs="6">
                      <Label className="m-0 font-lg">{RESUME.CREDIT}</Label>
                    </Col>
                    <Col xs="5" className="text-right">
                      <Label className="m-0 font-lg">
                        {`${
                          model.InstallmentCount
                        }x de ${model.InstallmentValue.BRLCurrency()}`}
                      </Label>
                    </Col>
                  </Col>
                </Row>
              )}
              <Row className="justify-content-center">
                <Col md="9">
                  <hr
                    style={{
                      border: '2px solid rgba(204, 204, 204, 0.6)',
                      borderStyle: 'none none solid none',
                      margin: '0px 0px 15px 0px',
                      padding: 0,
                    }}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="9" className="d-flex justify-content-end">
                  <span className="fw-600 pr-2 font-lg">
                    {RESUME.TOTAL_PRIZE}
                  </span>
                  <br />
                  <span className="fw-600 font-lg">
                    {model
                      && model.TotalPrize
                      && model.TotalPrize.BRLCurrency()}
                  </span>
                </Col>
              </Row>
            </Card>
            {ErrorPayment && (
              <Row className="justify-content-center text-center mb-2">
                <Col md={6}>
                  <Button
                    color="primary"
                    className="rounded font-lg w-100 m-0 fw-600"
                    onClick={() => {
                      window.open(`#/checkout/${this.token}`, '_self');
                    }}
                  >
                    {RESUME.PAYMENT_ERROR.PAYMENT_RETRY}
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </DefaultLayout>
    );
  }
}

Resume.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape() }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape(),
    path: PropTypes.string,
  }).isRequired,
  statePostCheckout: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listResume: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
  }).isRequired,
  stateValidate: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  getCheckout: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getCheckout,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  statePostCheckout: state.checkoutState.statePostCheckout,
  listResume: state.resumeState.listResume,
  stateValidate: state.resumeState.stateValidate,
});

export default connect(mapStateToProps, mapDispatchToProps)(Resume);
