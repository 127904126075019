import eventEmitter from './eventEmitter';
import { logout } from '../service/user';
import notify from '../mapper/common/notify';

const validateError = (data, defaultError) => {
  const errors = data.data.cause && data.data.cause.errors;
  if (errors) {
    return notify(errors[0].error, errors[0].message || 'Falha no servidor');
  }

  return defaultError;
};

export default (response) => {
  const base = {};

  const data = response && response.status ? response : response.response;

  if (!data) return base;

  if ((data.message && data.message.includes('Network Error')) || data.status === 503) {
    eventEmitter.guard('Serviço indisponível no momento, tente novamente mais tarde');
    base.state = false;
    base.data = null;
    base.success = false;
    return base;
  }

  if ((data.message && data.message.includes('Request failed with status code 401')) || data.status === 401) {
    eventEmitter.guard(validateError(data, 'Usuário ou senha inválido'));
    base.state = false;
    base.data = null;
    base.success = false;
    logout();
    return base;
  }

  if ((data.message && data.message.includes('Request failed with status code 404')) || data.status === 404) {
    eventEmitter.guard(validateError(data, 'URI não encontrado'));
    base.state = false;
    base.data = null;
    base.success = false;
    return base;
  }

  if ((data.message && data.message.includes('Request failed with status code 500')) || data.status === 500) {
    eventEmitter.guard(validateError(data, 'Falha no servidor'));
    base.state = false;
    base.data = null;
    base.success = false;
    return base;
  }

  if (data.status < 200 || data.status > 299) {
    eventEmitter.guard(validateError(data, 'Falha no servidor'));
    base.state = false;
    base.data = null;
    base.success = false;
    return base;
  }

  data.success = true;

  return data;
};
