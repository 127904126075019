import { axiosInstance as axios } from '../lib/axios';
import Config from '../config/environments';
import { REQUEST_TOKEN, CONFIRM_TOKEN } from '../actionTypes/token';
import { viewModelToModel } from '../mapper/token';
import { viewModelToModel as confirmViewModelToModel } from '../mapper/confirm';
import updateState from './common/common';

export const postRequestToken = (voucherId, data) => async () => {
  const base = {
    type: REQUEST_TOKEN,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.TOKEN.URL}/${voucherId}${Config.TOKEN.REQUEST}`;

  const response = await axios.post(url, data);

  base.payload = updateState(response);
  base.payload.data = viewModelToModel(base.payload.data);

  return base.payload;
};

export const postConfirmToken = (voucherId, tokenId, data) => async () => {
  const base = {
    type: CONFIRM_TOKEN,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.TOKEN.URL}/${voucherId}${Config.TOKEN.REQUEST}/${tokenId}${Config.TOKEN.CONFIRM}`;

  const response = await axios.post(url, data);

  base.payload = updateState(response);
  base.payload.data = confirmViewModelToModel(base.payload.data);

  return base.payload;
};
