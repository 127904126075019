import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Row, Col, Media, Label,
} from 'reactstrap';
import CHECKOUT from '../../const/checkout';

class TabInformation extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
    };
  }

  render() {
    const { listResume } = this.props;
    const model = (listResume && listResume.data) || {};

    return (
      <Col md="4" className="tab-information border-left border-right px-5 pb-4" style={{ backgroundColor: '#F5F5F5' }}>
        <Row className="m-0 mt-4">
          <Col className="flex-column d-flex align-items-center mt-3 px-0">
            <Media
              object
              src={model.IconType}
              alt="Logo"
              className="mt-3 mb-2"
              style={{ maxWidth: '130px' }}
            />
            <Label className="text-center w-100 m-0 pb-3 font-weight-bold m-0 label-side-info">
              {model.TextType}
            </Label>
          </Col>
        </Row>
        <Row className="m-0">
          <Col md="12" className="pb-1 mt-3 mb-1 px-0 border-bottom">
            <Label className="font-weight-bold label-side-info">{CHECKOUT.DETAILS.PROPOSAL_DATA}</Label>
          </Col>
        </Row>
        {model.Cars && model.Type === 'AUTO' && model.Cars.map((car, idx) => (
          <Row className="m-0" key={car.licensePlate}>
            <Col md="12" className="p-0">
              <span className="float-left pr-2 label-side-info">
                {`${CHECKOUT.DETAILS.VEHICLE_PLATE} #${idx + 1}:`}
              </span>
              <span className="float-left fw-600 label-side-info">
                {car.licensePlate}
              </span>
            </Col>
          </Row>
        ))}
        <Row className="m-0">
          <Col md="12" className="p-0">
            <span className="float-left pr-2 label-side-info">
              {CHECKOUT.DETAILS.PROPOSAL_NUMBER}
            </span>
            <span className={`float-left fw-600 label-side-info pb-2 w-100 ${model.Type === 'AUTO' && 'border-bottom'}`}>
              {model.ProposalNumber}
            </span>
          </Col>
        </Row>
        {model.Type !== 'AUTO' && (
          <Col md="12" className="p-0">
            <Label className="label-side-info pb-4 border-bottom w-100">
              {model.Document && model.Document.length === 10
                ? CHECKOUT.DETAILS.CNPJ : CHECKOUT.DETAILS.CPF}
              <strong className="ml-2 label-side-info">
                {model.Document}
              </strong>
            </Label>
          </Col>
        )}
        <Col md="12" className="pb-2 pt-2 px-0">
          <Label><strong className="label-side-info">{CHECKOUT.DETAILS.PROPOSAL_VALUE}</strong></Label>
        </Col>
        {model.HasPoints && (
          <Col md="12" className="p-0">
            <Label className="label-side-info w-100 m-0">
              {CHECKOUT.DETAILS.VOUCHERS}
              <strong className="ml-2 label-side-info">
                {`${model.Points.points}`}
              </strong>
            </Label>
          </Col>
        )}
        {model.HasBalance && (
          <Col md="12" className="p-0">
            <Label className="label-side-info w-100 m-0">
              {CHECKOUT.DETAILS.BALANCE}
              <strong className="ml-2 label-side-info">
                {model.Balance.value.BRLCurrency()}
              </strong>
            </Label>
          </Col>
        )}
        {model.HasOpenFinance && (
          <Col md="12" className="p-0">
            <Label className="label-side-info w-100 m-0">
              {CHECKOUT.DETAILS.PIX}
              <strong className="ml-2 label-side-info">
                {`${model.OpenFinance.value.BRLCurrency()}`}
              </strong>
            </Label>
          </Col>
        )}
        {model.HasCreditCard && (
          <Col md="12" className="p-0">
            <Label className="label-side-info w-100 m-0">
              {CHECKOUT.DETAILS.BALANCE_PAY}
              <strong className="ml-2 label-side-info">
                {`${model.CreditCard.value.BRLCurrency()} (${model.InstallmentCount}x de ${model.InstallmentValue && model.InstallmentValue.BRLCurrency()})`}
              </strong>
            </Label>
          </Col>
        )}
        <hr className="m-0 mt-3" style={{ borderColor: '#c8ced3' }} />
        <Col md="12" className="mt-3 p-0">
          <Label className="label-side-info w-100 font-weight-bold">
            {CHECKOUT.DETAILS.TOTAL_PRIZE}
            <strong className="ml-2 label-side-info">
              {`${model.TotalPrize && model.TotalPrize.BRLCurrency()}`}
            </strong>
          </Label>
        </Col>
      </Col>
    );
  }
}

TabInformation.defaultProps = {
};

TabInformation.propTypes = {
  listResume: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch,
);
const mapStateToProps = (state) => ({
  listResume: state.resumeState.listResume,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TabInformation));
