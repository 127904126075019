/* eslint-disable no-restricted-syntax */
const eventEmitter = {
  events: {},
  waiting: {},
  module: {},
  on(event, listener) {
    if (!this.events[event]) { this.events[event] = { listeners: [] }; }
    this.events[event].listeners.push(listener);
  },
  off(event) {
    delete this.events[event];
  },
  emit(name, ...payload) {
    for (const listener of this.events[name].listeners) {
      listener.apply(this, payload);
    }
  },
  get() {
    return this.waiting;
  },
  getByName(name) {
    const wait = this.waiting[name];
    return wait;
  },
  guard(...payload) {
    this.waiting.error = payload;
  },
  emitGuard(name) {
    for (const listener of this.events[name].listeners) {
      const payload = this.waiting[name];
      if (payload && payload !== {} && payload !== null && payload !== undefined) {
        listener.apply(this, payload);
        this.waiting[name] = null;
      }
    }
  },
  clear(name) {
    this.waiting[name] = null;
  },
  getModule(name) {
    return this.module[name];
  },
  getModuleByName(name) {
    const module = this.module[name];
    return module;
  },
  setModule(name, ...payload) {
    this.module[name] = payload;
  },
  clearModule(name) {
    this.module[name] = null;
  },
};

export default eventEmitter;
