import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { DefaultLayout } from '../../containers';
import { clearDataCancelOrder } from '../../actions/checkout';
import { getCheckout } from '../../actions/resume';
import Loading from '../utils/loading';
import Model from '../../model/checkout';
import { modelToFilterDetail } from '../../mapper/resume';
import PaymentFormComponent from '../component/paymentForm';
import CHECKOUT from '../../const/checkout';
import { cpfValidator, dateOfBirthValidator, cardDateValidator } from '../../lib/customValidade';
import Cookies from '../utils/cookies';

class Checkout extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleResize = this.handleResize.bind(this);
    this.state = {
      ...Model,
      Width: window.innerWidth,
    };

    const p = this.props;

    const checkoutToken = modelToFilterDetail(props.match.params);
    props.getCheckout(checkoutToken);
    p.clearDataCancelOrder();
  }

  async componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  shouldComponentUpdate(nextProps) {
    const { statePostCheckout } = nextProps;
    const p = this.props;

    if (statePostCheckout && statePostCheckout.success === true && !statePostCheckout.data) {
      toast.error('Um erro interno ocorreu');
      p.clearDataCancelOrder();
      return false;
    }

    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    this.setState({ Width: window.innerWidth });
  }

  render() {
    cpfValidator();
    dateOfBirthValidator();
    cardDateValidator();

    const {
      statePostCheckout, listResume, match, location,
    } = this.props;

    const { Width } = this.state;
    const isMobile = Width <= 999;

    const model = (listResume && listResume.data) || {};

    if (listResume && listResume.state !== false) {
      return <Loading msg={CHECKOUT.LOADING} />;
    }

    if ((!listResume || !listResume.data) && listResume.success === true) {
      return <Loading msg={CHECKOUT.LOADING} />;
    }

    if (!listResume.data && listResume.success === false) {
      return <Redirect to={{ pathname: '/invalid-token' }} />;
    }

    if (listResume.data && listResume.data.Status === 'CANCELLED') {
      return <Redirect to={{ pathname: '/invalid-token' }} />;
    }

    if (listResume.data && listResume.data.VoucherStatus === 'CANCELLED' && !model.OnlyCreditCard) {
      return <Redirect to={{ pathname: '/invalid-token' }} />;
    }

    if (listResume.data && (listResume.data.Status === 'CONFIRMED' || listResume.data.Status === 'PRE_AUTHORIZED_BY_GATEWAY')) {
      return <Redirect to={{ pathname: '/resume' }} />;
    }

    if (statePostCheckout && statePostCheckout.state === false && statePostCheckout.success === true
      && statePostCheckout.data && statePostCheckout.data.Status === 'FINISHED') {
      return <Redirect to={{ pathname: '/resume' }} />;
    }

    return (
      <DefaultLayout isMobile={isMobile}>
        <Cookies />
        <PaymentFormComponent isMobile={isMobile} match={match} location={location} />
      </DefaultLayout>
    );
  }
}

Checkout.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape(),
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape() }).isRequired,
  clearDataCancelOrder: PropTypes.func.isRequired,
  getCheckout: PropTypes.func.isRequired,
  statePostCheckout: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  stateRequestToken: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  stateConfirmToken: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listResume: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    clearDataCancelOrder,
    getCheckout,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  stateRequestToken: state.tokenState.stateRequestToken,
  stateConfirmToken: state.tokenState.stateConfirmToken,
  statePostCheckout: state.checkoutState.statePostCheckout,
  listResume: state.resumeState.listResume,
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
