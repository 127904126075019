import Model from '../model/token';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.Id = view.id;
    model.Status = view.status;

    return model;
  } catch (error) {
    throw new Error(`TokenMapper - ViewModelToModel = ${error}`);
  }
};

export const modelToViewModel = (model) => {
  try {
    if (!model) return null;

    const view = {};

    view.authOptionId = model.SelectPhone;

    return view;
  } catch (error) {
    throw new Error(`TokenMapper - ModelToViewModel = ${error}`);
  }
};

export const modelsToViewsModel = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.forEach(async (model) => {
      const view = modelToViewModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`TokenMapper - ModelsToViewsModel = ${error}`);
  }
};

export const viewModelToState = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    model.AuthOptionId = view.AuthOptionId;

    return model;
  } catch (error) {
    throw new Error(`TokenMapper - ViewModelToState = ${error}`);
  }
};
