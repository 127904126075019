import React from 'react';
import {
  Col, Row, Container,
} from 'reactstrap';
import Header from '../../containers/defaultHeader';
import Footer from '../../containers/defaultFooter';
import PAGE_404 from '../../const/404';

export default () => (
  <div className="container-page">
    <Header />
    <div className="app flex-row align-items-center h-100">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="clearfix" style={{ marginLeft: '80px' }}>
              <h1 className="float-left display-3 mr-4">{PAGE_404.TITLE}</h1>
              <h4 className="pt-3">{PAGE_404.SUBTITLE}</h4>
              <p className="text-muted float-left">{PAGE_404.DESCRIPTION}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <Footer />
  </div>
);
