import { POST_CHECKOUT, LIST_CHECKOUT } from '../actionTypes/checkout';

const BASE = {
  state: false,
  data: null,
  success: true,
};

const INITIAL_STATE = {
  statePostCheckout: BASE,
  listCheckout: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_CHECKOUT:
      return { ...state, statePostCheckout: action.payload };
    case LIST_CHECKOUT:
      return { ...state, listCheckout: action.payload };
    default:
      return state;
  }
};
