/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable no-console */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Row, Col, Card, Label, Button } from "reactstrap";
import ReactAutocomplete from "react-autocomplete";
import CHECKOUT from "../../const/checkout";

class OpenFinanceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Bank: "",
    };
  }

  render() {
    const {
      isMobile,
      listBanks,
      handleChangeState,
      listResume,
      confirmSection,
    } = this.props;

    const { SearchedBank, Bank, Avatar } = this.state;
    const modelCheckout = (listResume && listResume.data) || {};

    const model = (listBanks && listBanks.data && listBanks.data.list) || [];

    return (
      <>
        {!confirmSection && (
          <>
            <Row>
              <Col>
                <span>
                  <img
                    src="assets/icons/pix-icon.svg"
                    alt="Ícone Pix"
                    className="mr-2"
                  />
                  <img
                    src="assets/imgs/open-finance.svg"
                    width="140"
                    alt="Logo OpenFinance"
                  />
                </span>
              </Col>
            </Row>
            <Card className="py-2 rounded-0 mt-3">
              <Row className="justify-content-center">
                <Col xs={12}>
                  <Col>
                    <Label className="font-weight-bold font-lg">
                      {CHECKOUT.OPEN_FINANCE_FORM.CHOOSE_ONE_INSTITUTIONS}
                    </Label>
                    <br />
                    <Label style={{ color: "#404143" }}>
                      {CHECKOUT.OPEN_FINANCE_FORM.INSTITUTIONS}
                    </Label>
                  </Col>
                  <Row className="justify-content-center mt-2">
                    <Col className="d-flex justify-content-evenly">
                      {model.map((bank, idx) => {
                        if (idx > 4) {
                          return null;
                        }

                        return (
                          <Button
                            className="justify-content-center flex-column align-items-center border-0 rounded-0 p-1"
                            style={{
                              display: "flex",
                              cursor: "pointer",
                              backgroundColor:
                                Bank === bank.Name ? "#c8ced3" : "#FFFFFF",
                            }}
                            key={bank.Id}
                            onClick={() => {
                              handleChangeState(
                                false,
                                "SelectedBankId",
                                bank.Id
                              );
                              this.setState({
                                Bank: bank.Name,
                                Avatar: bank.Avatar,
                                SearchedBank: bank.Name,
                              });
                            }}
                          >
                            {bank.Avatar ? (
                              <img
                                src={bank.Avatar}
                                alt={bank.Name}
                                style={{
                                  float: "left",
                                  width: "36px",
                                  height: "36px",
                                  objectFit: "cover",
                                }}
                              />
                            ) : null}
                            <p className="m-0 font-xxs mt-1">{bank.Name}</p>
                          </Button>
                        );
                      })}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card
              className="pt-4 pl-4 pr-4 rounded-0"
              style={{ paddingBottom: 137 }}
            >
              <Row className="justify-content-center">
                <Col xs={12} md={5}>
                  <img
                    src="assets/icons/magnifying-glass-icon.svg"
                    alt="Ícone busca"
                    width={16}
                    style={{ position: "absolute", top: 8, left: 25 }}
                  />
                  <ReactAutocomplete
                    inputProps={{
                      className: "input-autocomplete w-100",
                      placeholder: "Buscar outras instituições",
                      required: "required",
                    }}
                    wrapperProps={{
                      className: "d-flex justify-content-center w-100",
                    }}
                    required="required"
                    items={model}
                    value={SearchedBank}
                    getItemValue={(item) => item.Id}
                    onSelect={(value) => {
                      const bankName = model.filter(
                        (bank) => bank.Id === value
                      )[0].Name;
                      const bankAvatar = model.filter(
                        (bank) => bank.Id === value
                      )[0].Avatar;

                      handleChangeState(false, "SelectedBankId", value);

                      this.setState({
                        Bank: bankName,
                        Avatar: bankAvatar,
                        SearchedBank: bankName,
                      });
                    }}
                    onChange={(e) => {
                      this.setState({
                        SearchedBank: e.target.value,
                      });
                    }}
                    shouldItemRender={(item, value) =>
                      item &&
                      item.Name.toLowerCase().includes(value.toLowerCase())
                    }
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={`Container-${item.Name}`}
                        style={{
                          background: isHighlighted ? "lightgray" : "white",
                        }}
                        className="option"
                      >
                        <img
                          width="20"
                          src={item.Avatar}
                          alt={item.Name}
                          className="mr-2"
                        />
                        {item.Name}
                      </div>
                    )}
                    renderMenu={(items, value, style) => (
                      <div className="container-options">
                        <Label className="font-xm">
                          {CHECKOUT.OPEN_FINANCE_FORM.COMPLETE_LIST}
                        </Label>
                        <div className="container-banks" style={{ ...style }}>
                          {items && items.length > 0 ? (
                            items.map((i) => i)
                          ) : (
                            <div className="option">
                              Nenhuma instituição encontrada
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  />
                </Col>
              </Row>
            </Card>
          </>
        )}
        {confirmSection && (
          <>
            <Row className={`mt-${isMobile ? 3 : 5}`}>
              <Col>
                <Label className="font-weight-bold font-lg">
                  {CHECKOUT.OPEN_FINANCE_FORM.TITLE}
                </Label>
              </Col>
            </Row>
            <Card className={`py-2 rounded-0 pt-3 pb-3 mb-${isMobile ? 3 : 2}`}>
              <Row className="justify-content-center pb-5">
                <Col xs={9}>
                  <Row>
                    <Col>
                      <Label className="font-weight-bold">
                        {CHECKOUT.OPEN_FINANCE_FORM.YOU_PAY}
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col>
                      <Label
                        className="w-50 pb-1 mb-2 font-weight-bold"
                        style={{
                          borderBottom: "2px solid rgba(204, 204, 204, 0.6)",
                        }}
                      >
                        {modelCheckout.OpenFinance.value.BRLCurrency()}
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label className="m-0">
                        {CHECKOUT.OPEN_FINANCE_FORM.ACCOUNT_ORIGIN}
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mb-5 justify-content-center align-items-center">
                    <Col>
                      <img
                        src={Avatar}
                        alt={Bank}
                        className="mr-2"
                        style={{
                          float: "left",
                          width: "20px",
                          height: "20px",
                          objectFit: "cover",
                        }}
                      />
                      <Label className="font-weight-bold">{Bank}</Label>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <div
                        className="d-flex mb-2"
                        style={{
                          borderBottom: "2px solid rgba(204, 204, 204, 0.6)",
                        }}
                      >
                        <img
                          src="assets/imgs/open-finance.svg"
                          width="140"
                          alt="Logo OpenFinance"
                          className="mb-2"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <img
                      src="assets/icons/information-icon.svg"
                      alt="Ícone informação"
                      className="position-absolute"
                      style={{ left: "-8px" }}
                    />
                    <Col>
                      <p className="font-xs">
                        {CHECKOUT.OPEN_FINANCE_FORM.CONFIRM_TRANSACTION}
                      </p>
                      <p className="font-xs">
                        {CHECKOUT.OPEN_FINANCE_FORM.CLOSE_TAB}
                      </p>
                      <p className="font-xs">
                        {CHECKOUT.OPEN_FINANCE_FORM.DONT_WORRY}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </>
        )}
      </>
    );
  }
}

OpenFinanceForm.defaultProps = {};

OpenFinanceForm.propTypes = {
  listResume: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  confirmSection: PropTypes.bool.isRequired,
  handleChangeState: PropTypes.func.isRequired,
  listBanks: PropTypes.shape({
    data: PropTypes.objectOf({
      list: PropTypes.arrayOf(PropTypes.object),
    }),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
const mapStateToProps = (state) => ({
  listBanks: state.bankState.listBanks,
  listResume: state.resumeState.listResume,
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenFinanceForm);
