import { Base64 } from 'js-base64';
import Model from '../model/resume';
import { viewsModelToModels as carViewsModelToModels } from './card';

export const viewModelToModel = (view) => {
  try {
    if (!view) return null;

    const model = { ...Model };

    let icon = '';
    let text = '';

    switch (view.type) {
      case 'AUTO':
        icon = 'assets/icons/auto.png';
        text = 'Seguro de Veículo';
        break;
      case 'HEALTH':
        icon = 'assets/icons/health.png';
        text = 'Seguro de Saúde';
        break;
      case 'LIFE':
        icon = 'assets/icons/life.png';
        text = 'Seguro de Vida';
        break;
      case 'HOME':
        icon = 'assets/icons/home.png';
        text = 'Seguro Residencial';
        break;
      case 'BUSINESS':
        icon = 'assets/icons/business.png';
        text = 'Seguro Empresarial';
        break;
      case 'CONDOMINIUM':
        icon = 'assets/icons/condominium.png';
        text = 'Seguro de Condomínio';
        break;
      case 'ELEMENTARY_BRANCH':
        icon = 'assets/icons/elementary_branch.png';
        text = 'Seguro de Ramos Elementares';
        break;
      default:
        icon = 'assets/icons/home.png';
        text = 'Seguro Residencial';
        break;
    }

    model.CompanyName = view.company && view.company.fantasyName;
    model.CompanyLogo = view.company && view.company.logo;
    model.CustomerId = view.customer.id;
    model.IconType = icon;
    model.Description = view.description;
    model.FirstName = view.customer.firstName;
    model.LastName = view.customer.fullName;
    model.FullName = view.customer.fullName;
    model.Email = view.customer.email;
    model.Document = view.customer.document;
    model.ProposalNumber = view.policyNumber;
    model.TotalPrize = view.amount || 0;
    model.TextType = text;
    model.Type = view.type;
    model.BalancePay = view.amountPayable || 0;
    model.InstallmentCount = view.checkoutPreferences.installmentCount || 0;
    model.InstallmentValue = view.checkoutPreferences.installmentValue || 0;
    model.ButtonConfirmBackground = view.checkoutPreferences.layout.colors.buttonConfirmBackground || '#000000';
    model.ButtonConfirmText = view.checkoutPreferences.layout.colors.buttonConfirmText || '#ffffff';
    model.TopLineBackground = view.checkoutPreferences.layout.colors.topLineBackground || '#000000';
    model.Cards = [];
    model.Cards = carViewsModelToModels(view.cards);
    model.PublicKey = view.security
    && view.security.encryptionKey && Base64.decode(view.security.encryptionKey);
    model.Status = view.status;
    model.Cars = [];
    model.Cars = view.informationsPolicy.cars;
    model.Phones = [];
    model.Phones = (view.voucher && view.voucher.authOptions && view.voucher.authOptions.sms) || [];
    model.Remaining = view && view.remaining
      && { Amount: view.remaining.amount, Installments: view.remaining.installments };
    model.VoucherStatus = (view.voucher && view.voucher.status) || '';
    model.ProviderName = (view.voucher && view.voucher.provider && view.voucher.provider.toUpperCase()) || '';
    model.PaymentInstruments = (view.checkoutPreferences
      && view.checkoutPreferences.paymentInstruments) || [];
    model.HasPreDefinedPayment = model.PaymentInstruments.length > 0;

    const creditCard = model.PaymentInstruments.filter((x) => x.type === 'CREDIT_CARD');
    const points = model.PaymentInstruments.filter((x) => x.type === 'LOYALTY_POINTS');
    const balance = model.PaymentInstruments.filter((x) => x.type === 'BALANCE');
    const openFinance = model.PaymentInstruments.filter((x) => x.type === 'OPEN_FINANCE');

    model.HasCreditCard = creditCard.length > 0;
    model.CreditCard = creditCard.length > 0 ? creditCard[0] : null;
    model.HasPoints = points.length > 0;
    model.Points = points.length > 0 ? points[0] : null;
    model.HasBalance = balance.length > 0;
    model.Balance = balance.length > 0 ? balance[0] : null;
    model.HasOpenFinance = openFinance.length > 0;
    model.OpenFinance = openFinance.length > 0 ? openFinance[0] : null;

    const payments = ['LOYALTY_POINTS', 'BALANCE', 'CREDIT_CARD', 'OPEN_FINANCE'];
    const paymentsInstruments = payments.reduce((acc, item, index) => {
      acc[item] = index;
      return acc;
    }, {});

    model.PaymentInstruments.sort(
      (a, b) => paymentsInstruments[a.type] - paymentsInstruments[b.type],
    );

    return model;
  } catch (error) {
    throw new Error(`ResumeMapper - viewModelToModel = ${error}`);
  }
};

export const viewsModelToModels = (models) => {
  try {
    if (!models) return null;

    const views = [];

    models.resumes.forEach((model) => {
      const view = viewModelToModel(model);

      views.push(view);
    });

    return views;
  } catch (error) {
    throw new Error(`ResumeMapper - viewsModelToModels = ${error}`);
  }
};

export const modelToFilterDetail = (params) => {
  try {
    if (!params) return null;

    const view = params.token;

    return view;
  } catch (error) {
    throw new Error(`ResumeMapper - ModelToFilter = ${error}`);
  }
};
