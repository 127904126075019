import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Card, Row, Col, Label, Media, CardBody, FormGroup, Input,
} from 'reactstrap';
import queryString from 'query-string';
import Cards from 'react-credit-cards';
import CHECKOUT from '../../const/checkout';
import { postDataCard } from '../../actions/card';
import Model from '../../model/checkout';
import {
  CpfMask, CreditCardMask, DateBirthMask, DateValidateMask,
} from '../../lib/mask';

class CreditCardForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ...Model,
      Requested: false,
    };
  }

  render() {
    const { listResume, location, handleChangeState } = this.props;
    const {
      CreditCardName, CreditCardNameValid, DateOfBirth, DateOfBirthValid,
      Document, DocumentValid, CreditCardNumber, CreditCardNumberValid,
      Cvv, CvvValid, DateValidate, DateValidateValid, CreditCardId, Focus,
      Requested,
    } = this.state;

    const query = queryString.parse(location.search);

    const requireCVV = (query.requireCVV || 'true') === 'true';

    const model = (listResume && listResume.data) || {};

    return (
      <>
        <Row className="mb-2">
          <Col md="12 d-flex align-items-center">
            <Media
              object
              src="assets/icons/credit-card-icon.svg"
              alt="Logo"
            />
            <Label className="mb-0 ml-2">
              <strong className="font-lg">
                {CHECKOUT.FORM.TITLE_CARD_SECTION}
              </strong>
            </Label>
          </Col>
        </Row>
        <Card className="py-2 px-4 rounded-0">
          <CardBody className="px-0">
            <Row>
              <Col>
                <FormGroup className="my-0">
                  <Row>
                    <Col md="6">
                      <Col xs="9">
                        <FormGroup>
                          <Input
                            type="text"
                            required="required"
                            maxLength="19"
                            minLength="14"
                            id="CreditCardNumber"
                            name="CreditCardNumber"
                            value={CreditCardNumber}
                            valid={CreditCardNumberValid}
                            invalid={CreditCardNumberValid === false}
                            disabled={Requested}
                            placeholder={
                              CHECKOUT.FORM.CREDIT_CARD_NUMBER_PLACEHOLDER
                            }
                            onChange={(e) => {
                              this.setState({
                                CreditCardNumber: CreditCardMask(e.target.value),
                                CreditCardNumberValid: e.target.validity.valid,
                              });
                              handleChangeState(e, 'CreditCardNumber', CreditCardMask(e.target.value));
                            }}
                            onFocus={(e) => this.setState({ Focus: e.target.name })}
                            className="inputs-card-data mb-4"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12">
                        <FormGroup>
                          <Input
                            type="text"
                            required="required"
                            maxLength="36"
                            id="CreditCardName"
                            name="CreditCardName"
                            value={CreditCardName}
                            valid={CreditCardNameValid}
                            invalid={CreditCardNameValid === false}
                            disabled={Requested}
                            placeholder={CHECKOUT.FORM.NAME_PLACEHOLDER}
                            onChange={(e) => {
                              this.setState({
                                CreditCardName: e.target.value,
                                CreditCardNameValid: e.target.validity.valid,
                              });
                              handleChangeState(e, 'CreditCardName', e.target.value);
                            }}
                            onFocus={(e) => this.setState({ Focus: e.target.name })}
                            className="inputs-card-data mb-4"
                          />
                        </FormGroup>
                      </Col>
                      <Row className="m-0 mb-4 justify-content-between">
                        <Col xs="5" className="pr-0">
                          <FormGroup className="m-0">
                            <Input
                              type="text"
                              required="required"
                              maxLength="7"
                              minLength="7"
                              id="DateValidate"
                              name="DateValidate"
                              value={DateValidate}
                              valid={DateValidateValid}
                              invalid={DateValidateValid === false}
                              disabled={Requested}
                              placeholder={CHECKOUT.FORM.DATE_VALIDATE_PLACEHOLDER}
                              className="card-date-validator inputs-card-data"
                              onChange={(e) => {
                                this.setState({
                                  DateValidate: DateValidateMask(e.target.value),
                                  DateValidateValid: e.target.validity.valid,
                                });
                                handleChangeState(e, 'DateValidate', DateValidateMask(e.target.value));
                              }}
                              onFocus={(e) => this.setState({
                                Focus: e.target.name,
                              })}
                            />
                          </FormGroup>
                        </Col>
                        {
                          requireCVV && (
                            <Col xs="5" className="pl-0">
                              <FormGroup className="m-0">
                                <Input
                                  type="text"
                                  required="required"
                                  minLength="3"
                                  maxLength="4"
                                  pattern="[0-9]*"
                                  id="Cvv"
                                  name="Cvv"
                                  value={Cvv}
                                  valid={CvvValid}
                                  invalid={CvvValid === false}
                                  disabled={Requested}
                                  placeholder={CHECKOUT.FORM.CVV_PLACEHOLDER}
                                  onChange={(e) => {
                                    this.setState({
                                      Cvv: e.target.value,
                                      CvvValid: e.target.validity.valid,
                                    });
                                    handleChangeState(e, 'Cvv', e.target.value);
                                  }}
                                  onFocus={(e) => (e ? this.setState({ Focus: 'cvc' }) : null)}
                                  className="inputs-card-data"
                                />

                              </FormGroup>
                            </Col>
                          )
                        }
                      </Row>
                      <Row className="m-0 justify-content-between">
                        <Col xs="6" className="pr-0">
                          <FormGroup className="m-0">
                            <Input
                              type="text"
                              required="required"
                              maxLength="14"
                              minLength="14"
                              id="Document"
                              name="Document"
                              value={Document}
                              valid={DocumentValid}
                              invalid={DocumentValid === false}
                              disabled={Requested}
                              placeholder={CHECKOUT.FORM.DOCUMENT_PLACEHOLDER}
                              className="cpf-validator inputs-card-data"
                              onChange={(e) => {
                                this.setState({
                                  Document: CpfMask(e.target.value),
                                  DocumentValid: e.target.validity.valid,
                                });
                                handleChangeState(e, 'Document', CpfMask(e.target.value));
                              }}
                              onKeyUp={(e) => {
                                this.setState({
                                  Document: CpfMask(e.target.value),
                                  DocumentValid: e.target.validity.valid,
                                });
                                handleChangeState(e, 'Document', CpfMask(e.target.value));
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="5" className="pl-0">
                          <FormGroup className="m-0">
                            <Input
                              type="text"
                              required="required"
                              maxLength="10"
                              minLength="10"
                              id="DateOfBirth"
                              name="DateOfBirth"
                              value={DateOfBirth}
                              valid={DateOfBirthValid}
                              invalid={DateOfBirthValid === false}
                              disabled={Requested}
                              placeholder={CHECKOUT.FORM.DATE_BIRTH_PLACEHOLDER}
                              className="date-of-birth-validator inputs-card-data"
                              onChange={(e) => {
                                this.setState({
                                  DateOfBirth: DateBirthMask(e.target.value),
                                  DateOfBirthValid: e.target.validity.valid,
                                });
                                handleChangeState(e, 'DateOfBirth', DateBirthMask(e.target.value));
                              }}
                              onFocus={(e) => this.setState({
                                Focus: e.target.name,
                              })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Col xs="12">
                        <FormGroup className="checkout">
                          {
                            model.Cards && model.Cards.length > 0
                              ? model.Cards.map((obj) => (
                                <Card
                                  key={obj.Id}
                                  onClick={
                                    () => this.setState({ CreditCardId: obj.Id })
                                  }
                                  className={CreditCardId ? 'active' : ''}
                                >
                                  <Row className="justify-content-center text-center">
                                    <Col md="12">
                                      <Media
                                        className="logo"
                                        object
                                        src={obj.ThumbUrl}
                                        alt="Logo"
                                        style={{ maxWidth: '50px', height: '28px' }}
                                      />
                                    </Col>
                                    <Col md="12">
                                      <p className="text-muted">{obj.LastFourDigits}</p>
                                    </Col>
                                  </Row>
                                </Card>
                              ))
                              : null
                          }
                        </FormGroup>
                      </Col>
                    </Col>
                    <Col md="6" xs="12" className="align-self-center p-0">
                      <FormGroup>
                        <Cards
                          cvc={Cvv}
                          expiry={DateValidate}
                          focused={Focus}
                          name={CreditCardName}
                          number={CreditCardNumber}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

CreditCardForm.defaultProps = {
};

CreditCardForm.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape(),
    search: PropTypes.string,
  }).isRequired,
  listResume: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  statePostCheckout: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  handleChangeState: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    postDataCard,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  statePostCheckout: state.checkoutState.statePostCheckout,
  listResume: state.resumeState.listResume,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardForm);
