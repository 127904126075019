import {
  cnpjValidatorFunct, cpfValidatorFunct, dateOfBirthValidatorFunct, cardDateValidatorFunct,
} from './customFunctionValidade';

export const cnpjValidator = () => {
  const inputs = document.getElementsByClassName('cnpj-validator');

  if (inputs.length > 0) {
    for (let index = 0; index < inputs.length; index += 1) {
      const nameInput = inputs[index];
      nameInput.addEventListener('input', (e) => cnpjValidatorFunct(e, nameInput));
      nameInput.addEventListener('focus', (e) => cnpjValidatorFunct(e, nameInput));
    }
  }
};

export const cpfValidator = () => {
  const inputs = document.getElementsByClassName('cpf-validator');

  if (inputs.length > 0) {
    for (let index = 0; index < inputs.length; index += 1) {
      const nameInput = inputs[index];
      nameInput.addEventListener('input', (e) => cpfValidatorFunct(e, nameInput));
      nameInput.addEventListener('focus', (e) => cpfValidatorFunct(e, nameInput));
    }
  }
};

export const dateOfBirthValidator = () => {
  const inputs = document.getElementsByClassName('date-of-birth-validator');

  if (inputs.length > 0) {
    for (let index = 0; index < inputs.length; index += 1) {
      const nameInput = inputs[index];
      nameInput.addEventListener('input', (e) => dateOfBirthValidatorFunct(e, nameInput));
      nameInput.addEventListener('focus', (e) => dateOfBirthValidatorFunct(e, nameInput));
    }
  }
};

export const cardDateValidator = () => {
  const inputs = document.getElementsByClassName('card-date-validator');

  if (inputs.length > 0) {
    for (let index = 0; index < inputs.length; index += 1) {
      const nameInput = inputs[index];
      nameInput.addEventListener('input', (e) => cardDateValidatorFunct(e, nameInput));
      nameInput.addEventListener('focus', (e) => cardDateValidatorFunct(e, nameInput));
    }
  }
};
