import { combineReducers } from 'redux';
import BankReducer from './banksReducer';
import CheckoutReducer from './checkoutReducer';
import ResumeReducer from './resumeReducer';
import CardReducer from './cardReducer';
import TokenReducer from './tokenReducer';

export default (asyncReducers) => combineReducers({
  bankState: BankReducer,
  checkoutState: CheckoutReducer,
  resumeState: ResumeReducer,
  cardState: CardReducer,
  tokenState: TokenReducer,
  ...asyncReducers,
});
