import Braintree from 'braintree';
import Config from '../config/environments';

export default () => {
  const gateway = new Braintree.BraintreeGateway({
    merchantId: Config.BRAINTREE.MERCHANT_ID,
    publicKey: Config.BRAINTREE.PUBLIC_KEY,
    privateKey: Config.BRAINTREE.PRIVATE_KEY,
    environment: Config.BRAINTREE.IS_SANDBOX ? Braintree.Environment.Sandbox
      : Braintree.Environment.Production,
  });

  return gateway;
};
