import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import DefaultHeader from './defaultHeader';
import DefaultFooter from './defaultFooter';

import { TabInfoComponent } from '../views/component';

const DefaultLayout = ({ children, isMobile }) => (
  <div className="container-page">
    <DefaultHeader />
    <div className="app-screens">
      <Container>
        <Row className="h-100">
          {isMobile && (
          <TabInfoComponent />
          )}
          <Col md="8">
            {children}
          </Col>
          {!isMobile && (
            <TabInfoComponent />
          )}
        </Row>
      </Container>
    </div>
    <DefaultFooter isCheckout="checkout" />
  </div>
);

DefaultLayout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
const mapStateToProps = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultLayout));
