import { axiosInstance as axios } from '../lib/axios';
import Config from '../config/environments';
import { POST_CARD } from '../actionTypes/card';
import updateState from './common/common';

export const postDataCard = (data, token) => async () => {
  const base = {
    type: POST_CARD,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.CARD.URL}/${token}${Config.CARD.CREDIT_CARD}`;

  const response = await axios.post(url, data);

  base.payload = updateState(response);

  return base.payload;
};

export const getDataCard = (data, token) => async () => {
  const base = {
    type: POST_CARD,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.CARD.URL}/${token}${Config.CARD.CREDIT_CARD}`;

  const response = await axios.post(url, data);

  base.payload = updateState(response);

  return base.payload;
};
