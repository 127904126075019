import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card, Col, Form, Label, Row,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import CHECKOUT from '../../const/checkout';

class BalanceForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
    };
  }

  render() {
    const { isMobile, listResume } = this.props;
    const model = (listResume && listResume.data) || {};

    return (
      <Form>
        <Card className="px-4 py-3 rounded-0">
          <Row className="justify-content-center">
            <Col xs={isMobile ? 10 : 9}>
              <Label className="font-weight-bold">{CHECKOUT.BALANCE_FORM.TITLE}</Label>
              <Label className="m-0">
                {CHECKOUT.BALANCE_FORM.ALREADY}
                <Label className="font-weight-bold m-0">{model.Balance.value.BRLCurrency()}</Label>
                {CHECKOUT.BALANCE_FORM.YOUR_BALANCE}
              </Label>
            </Col>
            <Col xs={isMobile ? 2 : 1} className="p-0 pl-3 align-self-center">
              <img src="assets/icons/wallet-icon.svg" alt="Ícone carteira" className="w-100" />
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

BalanceForm.defaultProps = {
};

BalanceForm.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  listResume: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  listResume: state.resumeState.listResume,
});

export default connect(mapStateToProps, mapDispatchToProps)(BalanceForm);
